.portfolio-list {
  font-size: 1rem;
  margin-right: 50px;
  padding: 0.8rem 1.2rem;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.portfolio-list:hover {
  background: var(--primary-color);
  color: white;
}

.portfolio-list.active {
  background: var(--primary-color);
  color: white;
}

@media screen and (max-width: 760px) {
  .portfolio-list {
    margin-right: 20px;
  }
}
