:root {
    --primary-color: #3d5a80;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    background: white;
    background-repeat: no-repeat;
    height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
    font-family: "Nunito", sans-serif;
}

.app-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
}