.menu {
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 2;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
}

.menu.active {
  right: 0;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 2.3rem;
  font-size: 300;
  color: white;
  width: 60%;
}

.menu li {
  margin-bottom: 25px;
  transition: all 0.1s ease;
}

.menu li:hover {
  color: white;
  transform: scale(1.03);
}

.menu .anchor {
  text-decoration: none;
  font-size: inherit;
  color: rgba(255, 255, 255, 0.986);
  cursor: pointer;
}
