.testimonials {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.testimonials ul {
  padding: 1rem 2rem;
  list-style: none;
}
